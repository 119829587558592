/* eslint-disable */
import {
    CommandBarButton,
    Nav,
    useTheme
} from '@fluentui/react';
import React, { useState } from 'react';
import "./HamburgerMenu.scss";

const HamburgerMenu = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);

    const globalNavStyles = {        
        root: {
            width: isNavExpanded ? 208 : 48,
            selectors: {
                '.ms-Button::after': {
                    width: 4,
                    height: 'calc(100% - 16px)',
                    borderRadius: 4,
                    top: 8,
                    left: 2,
                    background: '#60AAff',
                    borderLeft: 'unset'
                },
                i: {
                    margin: '0 12px',
                },
                'li:last-of-type': {
                    borderTop: `1px solid #3a3a3a`
                },
            },
        }
    }

    const navLinkGroups = [
            {
                name: 'Pages',
                links: [
                    {
                        name: 'Graph',
                        url: '/',
                        key: 'graphs-page',
                        iconProps: {iconName: 'GitGraph', styles: {root: {color: '#FFFFFF' } } }
                    },
                    {
                        name: '3D Scenes',
                        url: '/3DScenes',
                        key: 'scene-3D',
                        target: '_blank',
                        iconProps: {iconName: 'ProductVariant', styles: {root: {color: '#FFFFFF' } } }
                    },
                    {
                        name: 'Tools',
                        url: '/tools',
                        key: 'tools',
                        target: '_blank',
                        iconProps: {iconName: 'Toolbox', styles: {root: {color: '#FFFFFF' } } }
                    }
                ]
            }
        ];
        
    const hamburgerButtonStyle = {
        root: { 
            width: '100%', height: 44, display: 'flex', background: '#1e1e1e',
            selectors: {
                span: {
                    justifyContent: 'flex-start'
                }
            }
        },
        icon: {color: '#FFFFFF'}
    };

    function _onRenderGroupHeader() {
        return (
            <CommandBarButton
                styles={hamburgerButtonStyle}
                iconProps={{ iconName: 'GlobalNavButton' }}
                onClick={() => setIsNavExpanded(!isNavExpanded)}
            />
        );
    }

    return (
        <Nav
            ariaLabel="Navigation menu"
            groups={navLinkGroups}
            styles={{
                ...globalNavStyles
            }}
            selectedKey={'graphs-page'}
            onRenderGroupHeader={
                _onRenderGroupHeader
            }
            className={'leftNav'}
        />
    );
};

export default HamburgerMenu;
