module.exports = {
    "RatioUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:RatioUnit" },
    "UnitPrefix": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:UnitPrefix" },
    "DecimalUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:DecimalUnit" },
    "BinaryUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:BinaryUnit" },
    "SymbolicUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:SymbolicUnit" },
    "QuantitativeType": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:QuantitativeType" },
  
    "DecimalPrefix": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:DecimalPrefix" },
    "BinaryPrefix": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:BinaryPrefix" },
  
    "Acceleration": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Acceleration" },
    "Angle": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Angle" },
    "AngularAcceleration": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:AngularAcceleration" },
    "AngularVelocity": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:AngularVelocity" },
    "ApparentEnergy": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:ApparentEnergy" },
    "ApparentPower": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:ApparentPower" },
    "Area": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Area" },
    "Capacitance": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Capacitance" },
    "Concentration": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Concentration" },
    "Current": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Current" },
    "DataRate": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:DataRate" },
    "DataSize": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:DataSize" },
    "Density": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Density" },
    "Distance": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Distance" },
    "ElectricCharge": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:ElectricCharge" },
    "Energy": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Energy" },
    "EnergyRate": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:EnergyRate" },
    "Force": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Force" },
    "Frequency": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Frequency" },
    "Humidity": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Humidity" },
    "Illuminance": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Illuminance" },
    "Inductance": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Inductance" },
    "IonizingRadiationDose": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:IonizingRadiationDose" },
    "Irradiance": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Irradiance" },
    "Latitude": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Latitude" },
    "Longitude": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Longitude" },
    "Length": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Length" },
    "Luminance": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Luminance" },
    "Luminosity": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Luminosity" },
    "LuminousFlux": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:LuminousFlux" },
    "LuminousIntensity": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:LuminousIntensity" },
    "MagneticFlux": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:MagneticFlux" },
    "MagneticInduction": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:MagneticInduction" },
    "Mass": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Mass" },
    "MassFlowRate": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:MassFlowRate" },
    "Power": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Power" },
    "Pressure": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Pressure" },
    "Radioactivity": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Radioactivity" },
    "ReactiveEnergy": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:ReactiveEnergy" },
    "ReactivePower": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:ReactivePower" },
    "RelativeDensity": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:RelativeDensity" },
    "RelativeHumidity": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:RelativeHumidity" },
    "Resistance": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Resistance" },
    "SoundPressure": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:SoundPressure" },
    "Temperature": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Temperature" },
    "Thrust": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Thrust" },
    "TimeSpan": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:TimeSpan" },
    "Torque": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Torque" },
    "Velocity": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Velocity" },
    "Voltage": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Voltage" },
    "Volume": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:Volume" },
    "VolumeFlowRate": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:class:VolumeFlowRate" },
  
    "AccelerationUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:AccelerationUnit" },
    "AngleUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:AngleUnit" },
    "AngularAccelerationUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:AngularAccelerationUnit" },
    "AngularVelocityUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:AngularVelocityUnit" },
    "ApparentEnergyUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:ApparentEnergyUnit" },
    "ApparentPowerUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:ApparentPowerUnit" },
    "AreaUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:AreaUnit" },
    "CapacitanceUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:CapacitanceUnit" },
    "ChargeUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:ChargeUnit" },
    "CurrentUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:CurrentUnit" },
    "DataRateUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:DataRateUnit" },
    "DataSizeUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:DataSizeUnit" },
    "DensityUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:DensityUnit" },
    "EnergyUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:EnergyUnit" },
    "ForceUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:ForceUnit" },
    "FrequencyUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:FrequencyUnit" },
    "IlluminanceUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:IlluminanceUnit" },
    "InductanceUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:InductanceUnit" },
    "IonizingRadiationDoseUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:IonizingRadiationDoseUnit" },
    "IrradianceUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:IrradianceUnit" },
    "LengthUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:LengthUnit" },
    "LuminanceUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:LuminanceUnit" },
    "LuminousFluxUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:LuminousFluxUnit" },
    "LuminousIntensityUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:LuminousIntensityUnit" },
    "MagneticFluxUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:MagneticFluxUnit" },
    "MagneticInductionUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:MagneticInductionUnit" },
    "MassUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:MassUnit" },
    "MassFlowRateUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:MassFlowRateUnit" },
    "PowerUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:PowerUnit" },
    "PressureUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:PressureUnit" },
    "RadioactivityUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:RadioactivityUnit" },
    "ReactiveEnergyUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:ReactiveEnergyUnit" },
    "ReactivePowerUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:ReactivePowerUnit" },
    "ResistanceUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:ResistanceUnit" },
    "SoundPressureUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:SoundPressureUnit" },
    "TemperatureUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:TemperatureUnit" },
    "TimeUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:TimeUnit" },
    "TorqueUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:TorqueUnit" },
    "Unitless": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:Unitless" },
    "VelocityUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:VelocityUnit" },
    "VoltageUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:VoltageUnit" },
    "VolumeUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:VolumeUnit" },
    "VolumeFlowRateUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:enum:VolumeFlowRateUnit" },
  
    "unit": {
      "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:property:unit",
      "@type": "@vocab"
    },
    "topUnit": {
      "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:property:topUnit",
      "@type": "@vocab"
    },
    "bottomUnit": {
      "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:property:bottomUnit",
      "@type": "@vocab"
    },
    "baseUnit": {
      "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:property:baseUnit",
      "@type": "@vocab"
    },
    "prefix": {
      "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:property:prefix",
      "@type": "@vocab"
    },
  
    "deci": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:deci" },
    "centi": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:centi" },
    "milli": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:milli" },
    "micro": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:micro" },
    "nano": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:nano" },
    "pico": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:pico" },
    "femto": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:femto" },
    "atto": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:atto" },
    "zepto": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:zepto" },
    "yocto": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:yocto" },
    "deca": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:deca" },
    "hecto": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:hecto" },
    "kilo": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:kilo" },
    "mega": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:mega" },
    "giga": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:giga" },
    "tera": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:tera" },
    "peta": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:peta" },
    "exa": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:exa" },
    "zetta": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:zetta" },
    "yotta": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:yotta" },
    "kibi": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:kibi" },
    "mebi": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:mebi" },
    "gibi": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:gibi" },
    "tebi": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:tebi" },
    "pebi": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:pebi" },
    "exbi": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:exbi" },
    "zebi": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:zebi" },
    "yobi": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unitprefix:yobi" },
  
    "metrePerSecondSquared": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:metrePerSecondSquared" },
    "centimetrePerSecondSquared": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:centimetrePerSecondSquared" },
    "gForce": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gForce" },
    "radian": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:radian" },
    "degreeOfArc": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:degreeOfArc" },
    "minuteOfArc": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:minuteOfArc" },
    "secondOfArc": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:secondOfArc" },
    "turn": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:turn" },
    "radianPerSecondSquared": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:radianPerSecondSquared" },
    "radianPerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:radianPerSecond" },
    "degreePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:degreePerSecond" },
    "revolutionPerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:revolutionPerSecond" },
    "revolutionPerMinute": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:revolutionPerMinute" },
    "voltAmpereHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:voltAmpereHour" },
    "kilovoltAmpereHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilovoltAmpereHour" },
    "megavoltAmpereHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megavoltAmpereHour" },
    "gigavoltAmpereHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gigavoltAmpereHour" },
    "voltAmpere": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:voltAmpere" },
    "millivoltAmpere": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millivoltAmpere" },
    "kilovoltAmpere": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilovoltAmpere" },
    "megavoltAmpere": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megavoltAmpere" },
    "gigavoltAmpere": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gigavoltAmpere" },
    "squareMetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:squareMetre" },
    "squareCentimetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:squareCentimetre" },
    "squareMillimetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:squareMillimetre" },
    "squareKilometre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:squareKilometre" },
    "hectare": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:hectare" },
    "squareFoot": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:squareFoot" },
    "squareInch": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:squareInch" },
    "acre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:acre" },
    "farad": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:farad" },
    "millifarad": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millifarad" },
    "microfarad": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:microfarad" },
    "nanofarad": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:nanofarad" },
    "picofarad": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:picofarad" },
    "coulomb": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:coulomb" },
    "ampereHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:ampereHour" },
    "milliampereHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:milliampereHour" },
    "ampere": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:ampere" },
    "microampere": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:microampere" },
    "milliampere": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:milliampere" },
    "kiloampere": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kiloampere" },
    "bitPerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:bitPerSecond" },
    "kibibitPerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kibibitPerSecond" },
    "mebibitPerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:mebibitPerSecond" },
    "gibibitPerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gibibitPerSecond" },
    "tebibitPerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:tebibitPerSecond" },
    "exbibitPerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:exbibitPerSecond" },
    "zebibitPerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:zebibitPerSecond" },
    "yobibitPerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:yobibitPerSecond" },
    "bytePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:bytePerSecond" },
    "kibibytePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kibibytePerSecond" },
    "mebibytePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:mebibytePerSecond" },
    "gibibytePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gibibytePerSecond" },
    "tebibytePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:tebibytePerSecond" },
    "exbibytePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:exbibytePerSecond" },
    "zebibytePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:zebibytePerSecond" },
    "yobibytePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:yobibytePerSecond" },
    "bit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:bit" },
    "kibibit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kibibit" },
    "mebibit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:mebibit" },
    "gibibit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gibibit" },
    "tebibit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:tebibit" },
    "exbibit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:exbibit" },
    "zebibit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:zebibit" },
    "yobibit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:yobibit" },
    "byte": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:byte" },
    "kibibyte": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kibibyte" },
    "mebibyte": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:mebibyte" },
    "gibibyte": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gibibyte" },
    "tebibyte": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:tebibyte" },
    "exbibyte": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:exbibyte" },
    "zebibyte": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:zebibyte" },
    "yobibyte": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:yobibyte" },
    "kilogramPerCubicMetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilogramPerCubicMetre" },
    "gramPerCubicMetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gramPerCubicMetre" },
    "milligramPerCubicMetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:milligramPerCubicMetre" },
    "microgramPerCubicMetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:microgramPerCubicMetre" },
    "joule": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:joule" },
    "kilojoule": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilojoule" },
    "megajoule": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megajoule" },
    "gigajoule": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gigajoule" },
    "electronvolt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:electronvolt" },
    "megaelectronvolt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megaelectronvolt" },
    "wattHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:wattHour" },
    "milliwattHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:milliwattHour" },
    "kilowattHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilowattHour" },
    "megawattHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megawattHour" },
    "gigawattHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gigawattHour" },
    "terawattHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:terawattHour" },
    "britishThermalUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:britishThermalUnit" },
    "kiloBritishThermalUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kiloBritishThermalUnit" },
    "joulePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:joulePerSecond" },
    "kilojoulePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilojoulePerSecond" },
    "joulePerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:joulePerHour" },
    "kilojoulePerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilojoulePerHour" },
    "megajoulePerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megajoulePerHour" },
    "gigajoulePerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gigajoulePerHour" },
    "britishThermalUnitPerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:britishThermalUnitPerHour" },
    "kiloBritishThermalUnitPerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kiloBritishThermalUnitPerHour" },
    "newton": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:newton" },
    "pound": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:pound" },
    "ounce": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:ounce" },
    "ton": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:ton" },
    "hertz": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:hertz" },
    "millihertz": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millihertz" },
    "kilohertz": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilohertz" },
    "megahertz": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megahertz" },
    "gigahertz": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gigahertz" },
    "lux": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:lux" },
    "footcandle": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:footcandle" },
    "henry": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:henry" },
    "millihenry": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millihenry" },
    "microhenry": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:microhenry" },
    "gray": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gray" },
    "milligray": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:milligray" },
    "microgray": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:microgray" },
    "sievert": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:sievert" },
    "millisievert": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millisievert" },
    "microsievert": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:microsievert" },
    "wattPerSquareMetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:wattPerSquareMetre" },
    "metre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:metre" },
    "centimetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:centimetre" },
    "millimetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millimetre" },
    "micrometre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:micrometre" },
    "nanometre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:nanometre" },
    "kilometre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilometre" },
    "foot": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:foot" },
    "inch": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:inch" },
    "mile": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:mile" },
    "nauticalMile": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:nauticalMile" },
    "astronomicalUnit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:astronomicalUnit" },
    "candelaPerSquareMetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:candelaPerSquareMetre" },
    "lumen": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:lumen" },
    "candela": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:candela" },
    "weber": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:weber" },
    "maxwell": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:maxwell" },
    "tesla": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:tesla" },
    "gauss": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gauss" },
    "kilogram": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilogram" },
    "gram": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gram" },
    "milligram": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:milligram" },
    "microgram": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:microgram" },
    "tonne": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:tonne" },
    "slug": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:slug" },
    "massPound": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:massPound" },
    "gramPerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gramPerSecond" },
    "kilogramPerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilogramPerSecond" },
    "gramPerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gramPerHour" },
    "kilogramPerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilogramPerHour" },
    "massPoundPerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:massPoundPerHour" },
    "watt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:watt" },
    "microwatt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:microwatt" },
    "milliwatt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:milliwatt" },
    "kilowatt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilowatt" },
    "megawatt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megawatt" },
    "gigawatt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gigawatt" },
    "horsepower": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:horsepower" },
    "kilowattHourPerYear": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilowattHourPerYear" },
    "tonOfRefrigeration": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:tonOfRefrigeration" },
    "pascal": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:pascal" },
    "decapascal": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:decapascal" },
    "hectopascal": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:hectopascal" },
    "kilopascal": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilopascal" },
    "bar": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:bar" },
    "millibar": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millibar" },
    "millimetresOfMercury": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millimetresOfMercury" },
    "poundPerSquareInch": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:poundPerSquareInch" },
    "inchesOfMercury": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:inchesOfMercury" },
    "inchesOfWater": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:inchesOfWater" },
    "becquerel": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:becquerel" },
    "kilobecquerel": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilobecquerel" },
    "megabecquerel": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megabecquerel" },
    "gigabecquerel": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gigabecquerel" },
    "voltAmpereReactiveHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:voltAmpereReactiveHour" },
    "kilovoltAmpereReactiveHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilovoltAmpereReactiveHour" },
    "megavoltAmpereReactiveHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megavoltAmpereReactiveHour" },
    "gigavoltAmpereReactiveHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gigavoltAmpereReactiveHour" },
    "voltAmpereReactive": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:voltAmpereReactive" },
    "millivoltAmpereReactive": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millivoltAmpereReactive" },
    "kilovoltAmpereReactive": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilovoltAmpereReactive" },
    "megavoltAmpereReactive": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megavoltAmpereReactive" },
    "gigavoltAmpereReactive": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gigavoltAmpereReactive" },
    "ohm": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:ohm" },
    "milliohm": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:milliohm" },
    "kiloohm": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kiloohm" },
    "megaohm": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megaohm" },
    "decibel": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:decibel" },
    "bel": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:bel" },
    "kelvin": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kelvin" },
    "degreeCelsius": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:degreeCelsius" },
    "degreeFahrenheit": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:degreeFahrenheit" },
    "second": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:second" },
    "millisecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millisecond" },
    "microsecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:microsecond" },
    "nanosecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:nanosecond" },
    "minute": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:minute" },
    "hour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:hour" },
    "day": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:day" },
    "year": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:year" },
    "unity": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:unity" },
    "percent": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:percent" },
    "partsPerMillion": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:partsPerMillion" },
    "partsPerBillion": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:partsPerBillion" },
    "partsPerTrillion": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:partsPerTrillion" },
    "partsPerQuadrillion": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:partsPerQuadrillion" },
    "newtonMetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:newtonMetre" },
    "metrePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:metrePerSecond" },
    "centimetrePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:centimetrePerSecond" },
    "kilometrePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilometrePerSecond" },
    "metrePerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:metrePerHour" },
    "kilometrePerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilometrePerHour" },
    "milePerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:milePerHour" },
    "milePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:milePerSecond" },
    "knot": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:knot" },
    "volt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:volt" },
    "millivolt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millivolt" },
    "microvolt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:microvolt" },
    "kilovolt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:kilovolt" },
    "megavolt": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:megavolt" },
    "cubicMetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:cubicMetre" },
    "cubicCentimetre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:cubicCentimetre" },
    "litre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:litre" },
    "millilitre": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millilitre" },
    "cubicFoot": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:cubicFoot" },
    "cubicInch": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:cubicInch" },
    "fluidOunce": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:fluidOunce" },
    "gallon": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gallon" },
    "litrePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:litrePerSecond" },
    "millilitrePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millilitrePerSecond" },
    "litrePerMinute": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:litrePerMinute" },
    "millilitrePerMinute": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millilitrePerMinute" },
    "litrePerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:litrePerHour" },
    "millilitrePerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:millilitrePerHour" },
    "gallonPerMinute": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gallonPerMinute" },
    "gallonPerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:gallonPerHour" },
    "cubicFootPerMinute": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:cubicFootPerMinute" },
    "cubicMetrePerHour": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:cubicMetrePerHour" },
    "cubicMetrePerMinute": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:cubicMetrePerMinute" },
    "cubicMetrePerSecond": { "@id": "dtmi:dtdl:extension:quantitativeTypes:v1:unit:cubicMetrePerSecond" }
  }