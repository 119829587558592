import { createEnum } from "../../../utils/utilities";

/** The property names for custom properties in the payload */
export const CUSTOM_PROPERTY_NAMES = {
  // Hashed value for the adt instance
  AdtInstanceHash: "AdtInstanceHash",
  // Region of the app (top nav/query bar/left panel/graph viewer)
  AppRegion: "AppRegion",
  // Name of the emitting component
  ComponentName: "ComponentName",
  // Type of action (user, system, etc.)
  TriggerType: "TriggerType"
};

/** Highest level sections of the app */
export const AppRegion = createEnum([
  "ApiService",
  "TopNav",
  "QueryCommandBar",
  "TwinsPanel",
  "ModelPanel",
  "TwinGraph",
  "ModelGraph",
  "BottomArea"
]);

/** The type of gesture the user used, (click, hover, etc.) */
export const Gesture = createEnum([ "Click", "Hover" ]);

/** The source of the event, was it from a user or an automated event based off of some condition
 * user initiated action or view, and System initiated action
*/
export const TelemetryTrigger = createEnum([ "UserAction", "UserView", "SystemAction" ]);

/**
 * The high level component emitting the event
 * Keep it at the general level, no need to get overly specific.
 * Ex: QueryExplorer, ModelViewer, etc
 */
export const ComponentName = createEnum([
  "App",
  "AppCommandBar",
  "Query",
  "ModelViewer",
  "ModelViewerItem",
  "ModelViewerCommandBar",
  "TwinViewer",
  "ModelGraphViewer",
  "GraphViewer",
  "Console"
]);


export const SeverityLevel = createEnum([
  "Verbose",
  "Information",
  "Warning",
  "Error",
  "Critical"
]);

/** Loosely based on the Application insights telemetry data model
 * https://docs.microsoft.com/en-us/azure/azure-monitor/app/data-model
 */
export const TelemetryType = createEnum([
  "event",
  "exception",
  "metric",
  "request",
  "trace"
]);

/**
 * The events the app can emit
 * Structure is like this:
 *  - App region (App Service or any client region like top nav, query explorer, twin and model graphs/panels)
 *    - High level component (TwinPanelItem, GraphViewerPivot, etc)
 *      - User action/System action
 *        - Event/action
 *           - variant (ex: initiate, confirm, cancel)
 *           - eventName: the name of the beacon to emit
 *           - properties: names of the custom properties that the event will have attached to it
 */
export const TelemetryEvents = {
  // Api service region
  ApiService: {
    AppComponent: {
      SystemAction: {
        LoadApp: {
          eventName: "AppComponent.LoadApp"
        }
      }
    }
  },
  // UI region with feedback and settings buttons
  TopNav: {
    AppCommandBarComponent: {
      UserAction: {
        ClickSaveConfiguration: {
          eventName: "AppCommandBarComponent.ClickSaveConfiguration",
          properties: {
            inLocalStorage: "inLocalStorage"
          },
          DeleteAllTwins: {
            eventName: "AppCommandBarComponent.DeleteAllTwins"
          }
        }
      }
    }
  },
  // UI region with query input and buttons
  QueryExplorer: {
    QueryComponent: {
      UserAction: {
        ClickSaveQuery: {
          eventName: "QueryComponent.ClickSaveQuery"
        },
        ClickShareQuery: {
          eventName: "QueryComponent.ClickShareQuery"
        },
        ExecuteQuery: {
          eventName: "QueryComponent.ExecuteQuery",
          properties: {
            isOverlayEnabled: "isOverlayEnabled"
          }
        }
      }
    }
  },
  // UI region of twins related left panel items
  TwinsPanel: {
    TwinViewerComponent: {
      UserAction: {
        ClickPivot: {
          eventName: "TwinViewerComponent.ClickPivot"
        }
      }
    }
  },
  // UI region of model related left panel items
  ModelPanel: {
    ModelViewerComponent: {
      UserAction: {
        ClickPivot: {
          eventName: "ModelViewerComponent.ClickPivot"
        },
        ClickCreateTwin: {
          eventName: "ModelViewerComponent.ClickCreateTwin"
        },
        ClickDeleteModel: {
          eventName: "ModelViewerComponent.ClickDeleteModel"
        },
        ClickDeleteAllModels: {
          eventName: "ModelViewerComponent.ClickDeleteAllModels"
        },
        ClickRefreshModels: {
          eventName: "ModelViewerComponent.ClickRefreshModels"
        },
        ClickSelectModel: {
          eventName: "ModelViewerComponent.ClickSelectModel"
        },
        ClickViewModel: {
          eventName: "ModelViewerComponent.ClickViewModel"
        },
        ClickUploadModelImage: {
          eventName: "ModelViewerComponent.ClickUploadModelImage"
        },
        ClickUploadModelImagesBulk: {
          eventName: "ModelViewerComponent.ClickUploadModelImagesBulk"
        },
        ClickUploadModel: {
          eventName: "ModelViewerComponent.ClickUploadModel"
        },
        ClickUploadModelsBulk: {
          eventName: "ModelViewerComponent.ClickUploadModelsBulk"
        },
        CreateTwin: {
          eventName: "ModelViewerComponent.CreateTwin"
        },
        DeleteModel: {
          eventName: "ModelViewerComponent.DeleteModel"
        },
        DeleteAllModels: {
          eventName: "ModelViewerComponent.DeleteAllModels"
        }
      },
      SystemAction: {
        LoadModels: {
          eventName: "ModelViewerComponent.LoadModels",
          properties: {
            modelCount: "modelCount"
          }
        }
      }
    }
  },
  // UI region of twin graph display
  TwinGraph: {
    GraphViewerComponent: {
      UserAction: {
        // Click pivot to render list
        ClickChangeDisplayPropertyName: {
          eventName: "GraphViewerComponent.ClickChangeDisplayPropertyName"
        },
        ClickExpandNode: {
          eventName: "GraphViewerComponent.ClickExpandNode",
          properties: {
            expansionLevel: "expansionLevel"
          }
        },
        ClickGraphExport: {
          eventName: "GraphViewerComponent.ClickGraphExport"
        },
        ClickGraphImport: {
          eventName: "GraphViewerComponent.ClickGraphImport"
        },
        ClickPivot: {
          eventName: "GraphViewerComponent.ClickPivot"
        },
        ClickAddFilterTerm: {
          eventName: "GraphViewerComponent.ClickAddFilterTerm"
        },
        ClickAddHighlightTerm: {
          eventName: "GraphViewerComponent.ClickAddHighlightTerm"
        },
        ClickRemoveFilterTerm: {
          eventName: "GraphViewerComponent.ClickRemoveFilterTerm"
        },
        ClickRemoveHighlightTerm: {
          eventName: "GraphViewerComponent.ClickRemoveHighlightTerm"
        },
        DeleteRelationship: {
          eventName: "GraphViewerComponent.DeleteRelationship",
          properties: {
            deleteCount: "deleteCount"
          }
        },
        DeleteTwin: {
          eventName: "GraphViewerComponent.DeleteTwin",
          properties: {
            deleteCount: "deleteCount"
          }
        },
        SaveRelationship: {
          eventName: "GraphViewerComponent.SaveRelationship",
          properties: {
            isCreate: "isCreate"
          }
        }
      },
      SystemAction: {
        LoadAllRelationships: {
          eventName: "GraphViewerComponent.LoadAllRelationships",
          properties: {
            relationshipCount: "relationshipCount"
          }
        },
        LoadRelationship: {
          eventName: "GraphViewerComponent.LoadRelationship"
        },
        LoadTwins: {
          eventName: "GraphViewerComponent.LoadTwins",
          properties: {
            twinCount: "twinCount"
          }
        },
        LoadFilterNodes: {
          eventName: "GraphViewerComponent.LoadFilterNodes",
          properties: {
            filterNodeCount: "filterNodeCount"
          }
        },
        LoadHighlightNodes: {
          eventName: "GraphViewerComponent.LoadHighlightNodes",
          properties: {
            highlightNodeCount: "highlightNodeCount"
          }
        }
      }
    }
  },
  // UI region of model graph display
  ModelGraph: {
    ModelGraphViewerComponent: {
      UserAction: {
        // Click pivot to render list
        ClickPivot: {
          eventName: "ModelGraphViewerComponent.ClickPivot"
        },
        ClickAddFilterTerm: {
          eventName: "ModelGraphViewerComponent.ClickAddFilterTerm"
        },
        ClickAddHighlightTerm: {
          eventName: "ModelGraphViewerComponent.ClickAddHighlightTerm"
        },
        ClickRemoveFilterTerm: {
          eventName: "ModelGraphViewerComponent.ClickRemoveFilterTerm"
        },
        ClickRemoveHighlightTerm: {
          eventName: "ModelGraphViewerComponent.ClickRemoveHighlightTerm"
        }
      },
      SystemAction: {
        LoadFilterNodes: {
          eventName: "ModelGraphViewerComponent.LoadFilterNodes",
          properties: {
            filterNodeCount: "filterNodeCount"
          }
        },
        LoadHighlightNodes: {
          eventName: "ModelGraphViewerComponent.LoadHighlightNodes",
          properties: {
            highlightNodeCount: "highlightNodeCount"
          }
        }
      }
    }
  },
  // UI region of console and output
  BottomArea: {
    ConsoleComponent: {
      UserAction: {
        ExecuteConsoleCommand: {
          eventName: "ConsoleComponent.ExecuteConsoleCommand",
          properties: {
            command: "Command"
          }
        }
      }
    }
  }
};

/**
 * The metrics the app can emit
 * Structure is like this:
 *  - App region (App Service or any client region like top nav, query explorer, twin and model graphs/panels)
 *    - High level component (TwinPanelItem, GraphViewerPivot, etc)
 *      - User action/System metric
 *        - Event/action
 *           - eventName: the name of the beacon to emit
 *           - properties: names of the custom properties that the event will have attached to it
 */
export const TelemetryMetrics = { ApiService: {}, TopNav: {}, QueryExplorer: {}, LeftPanel: {}, GraphViewer: {} };
