/* eslint-disable */
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { LOCAL_STORAGE_KEYS } from "./Constants";

class AppInsightsService {
    constructor() {
        const useDevInstance = localStorage.getItem(LOCAL_STORAGE_KEYS.FeatureFlags.Telemetry.forceDevTelemetryEndpoint) === "true";
        const isProdUrl = window.location.href.startsWith("https://explorer.digitaltwins.azure.net/");
        const isLocal = process.env.NODE_ENV !== "production";
        const useDevAppInsights = useDevInstance || isLocal || !isProdUrl;
        this.instance = new ApplicationInsights({ config: {
            connectionString: useDevAppInsights ? 'InstrumentationKey=17b2eb1e-4bd0-4a04-8f18-e2afc7d157a7;IngestionEndpoint=https://southcentralus-3.in.applicationinsights.azure.com/;LiveEndpoint=https://southcentralus.livediagnostics.monitor.azure.com/' : 'InstrumentationKey=b1389683-982c-4c01-926b-33f432c2c274;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/',
            /* ...Other Configuration Options... */
            disableAjaxTracking: true,
        }});
        this.instance.loadAppInsights();
        this.instance.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
        
        // Filter all query, search, and code parameters from URLs, only keeping origin + pathname
        this.instance.addTelemetryInitializer((environment) => {
            const uri = environment?.baseData?.uri;
            try {
                const parsedUri = new URL(uri);
                environment.baseData.uri = parsedUri.origin + parsedUri.pathname;
            } catch (err) {
                environment.baseData.uri = "";
            }
        })
    }
}

export default new AppInsightsService().instance;
