/* eslint-disable linebreak-style */
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useId } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { IconButton } from "office-ui-fabric-react";
import {
  Callout,
  DirectionalHint,
  Text,
  mergeStyleSets
} from "@fluentui/react";
import "../QueryComponent/QueryComponent.scss";
import { configService } from "../../services/ConfigService";
import MsalAuthService from "../../services/MsalAuthService";
import TelemetryService from "../../services/TelemetryService";
import {TelemetryTrigger, ComponentName, AppRegion, TelemetryEvents } from "../../services/models/Constants/TelemetryConstants";
import { TelemetryEvent } from "../../services/models/Telemetry";

const ICON_REVERT_DELAY = 3000;
const ROOT_LOC = "queryComponent";
const LOC_KEYS = {
  shareIcon: `${ROOT_LOC}.shareButton`,
  acceptIcon: `${ROOT_LOC}.acceptButton`,
  linkCopied: `${ROOT_LOC}.linkCopied`,
  failedToCopy: `${ROOT_LOC}.failedToCopy`
};

export default function ShareQueryButton({ query }) {
  // States
  const [ iconName, setIconName ] = useState(LOC_KEYS.shareIcon);
  const [ showFlyout, setShowFlyout ] = useState(false);

  // Hooks
  const shareButtonId = useId();
  const confirmationFadeoutTimeout = useRef("");
  const { t } = useTranslation();
  const link = useRef("");

  useEffect(() => {
    async function getLink() {
      let config = {};
      config = await configService.getConfig();
      const { appAdtUrl } = config;
      const baseUrl = window.location.origin;
      const tid = MsalAuthService.tenantId;
      const eid = appAdtUrl?.split("//")[1];
      link.current = `${baseUrl}/?query=${encodeURI(
        query
      )}&tid=${tid}&eid=${eid}`;
    }
    getLink();
  }, [ query ]);

  const copyText = useCallback(async url => {
    try {
      await navigator.clipboard.writeText(url);
      setIconName(LOC_KEYS.acceptIcon);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to copy text to clipboard");
    } finally {
      confirmationFadeoutTimeout.current = setTimeout(() => {
        setIconName(LOC_KEYS.shareIcon);
        setShowFlyout(false);
      }, ICON_REVERT_DELAY);
    }
  }, []);

  const handleShareQueryButtonClick = useCallback(() => {
    copyText(link.current);
    setShowFlyout(current => !current);
    // Log sharing query
    TelemetryService.sendEvent(
      new TelemetryEvent({
        triggerType: TelemetryTrigger.UserAction,
        componentName: ComponentName.Query,
        appRegion: AppRegion.QueryCommandBar,
        name: TelemetryEvents.QueryExplorer.QueryComponent.UserAction.ClickShareQuery.eventName
      })
    );
  }, []);

  const onDismiss = useCallback(() => {
    setShowFlyout(false);
  }, []);
  return (
    <>
      <IconButton
        className="share-query-button"
        iconProps={{ iconName: t(iconName), style: { color: "black" } }}
        title={t(LOC_KEYS.shareIcon)}
        ariaLabel={t(LOC_KEYS.shareIcon)}
        ariaLive="assertive"
        onClick={handleShareQueryButtonClick}
        id={shareButtonId} />
      {showFlyout && (
        <Callout
          onDismiss={onDismiss}
          setInitialFocus
          target={`#${shareButtonId}`}
          directionalHint={DirectionalHint.bottomCenter}
          className={styles.callout}>
          <Text>{t(LOC_KEYS.linkCopied)}</Text>
        </Callout>
      )}
    </>
  );
}

const styles = mergeStyleSets({
  callout: {
    padding: 16,
    backdropFilter: "blur(24px) brightness(150%)"
  }
});
