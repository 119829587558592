/* eslint-disable */
import * as Msal from "@azure/msal-browser";

export const DEFAULT_TENANT_ID = "common";
export default class MsalAuthService {

    static getTokenCalls = [];

    static gettingToken = false;

    static authContext;

    static executeGetTokenSequentially = true;

    static tenantId = DEFAULT_TENANT_ID;

    static environmentToConstantMapping = {
      thirdPartyDev: {
        login: "https://login.microsoftonline.com/",
        clientId: "e7e88070-28a1-43a3-9704-d8b986eb5f60",
        tsiApi: "https://api.timeseries.azure.com/",
        management: "https://management.core.windows.net/",
        // The resource URI for ADT should NOT end with a trailing slash as it will cause
        // Authentication to fail.
        adtApi: "https://digitaltwins.azure.net",
        adxApi: 'https://help.kusto.windows.net',
      },
      ppe: {
        login: "https://login.windows-ppe.net/",
        clientId: "5ff3e87f-63ef-4bb2-8d2d-89a6243a686f",
        tsiApi: "https://api.timeseries.azure.com/",
        management: "https://management.core.windows.net/",
        // The resource URI for ADT should NOT end with a trailing slash as it will cause
        // Authentication to fail.
        adtApi: "https://digitaltwins.azure.net",
        adxApi: 'https://help.kusto.windows.net',
      },
      prod: {
        login: "https://login.microsoftonline.com/",
        clientId: "85607549-2ae8-44d7-85f8-f3405d0a595c",
        tsiApi: "https://api.timeseries.azure.com/",
        management: "https://management.azure.com/",
        // The resource URI for ADT should NOT end with a trailing slash as it will cause
        // Authentication to fail.
        adtApi: "https://digitaltwins.azure.net",
        adxApi: 'https://help.kusto.windows.net',
      }
    }

    static getConstantValue = key => MsalAuthService.environmentToConstantMapping.prod[key]

    static authContextConfig = {
      auth: {
        clientId: MsalAuthService.getConstantValue("clientId"),
        authority: `${MsalAuthService.getConstantValue("login")}${DEFAULT_TENANT_ID}`,
        redirectUri: `${window.location.protocol}//${window.location.hostname}`,
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
      }
    }

    static authContext = new Msal.PublicClientApplication(MsalAuthService.authContextConfig);

    static login = continuation => {

      const getTenantsAndContinuation = (name, userName) => {
        MsalAuthService.getManagementToken(true).then(token => {
            MsalAuthService.promiseHttpRequest(token, `https://management.azure.com/tenants?api-version=2020-01-01`, {}, "GET")
              .then(tenants => {
                try{
                  let responsePayload = JSON.parse(tenants);
                  tenants = responsePayload.value;
                  continuation(name, userName, tenants);
                }
                catch(e){
                  continuation(name, userName, []);
                }
              })
              .catch(err => {
                console.log(err);
                continuation(name, userName, []);
              });
        });
      };

      MsalAuthService.authContext.handleRedirectPromise().then(tokenResponse => {
        if (tokenResponse !== null) {
          const activeAccount = tokenResponse.account;
          MsalAuthService.authContext.setActiveAccount(activeAccount);
          getTenantsAndContinuation(activeAccount.name, activeAccount.username);
        } else {
          const currentAccounts = MsalAuthService.authContext.getAllAccounts();
          if (!currentAccounts || currentAccounts.length < 1) {
            MsalAuthService.authContext.loginRedirect({prompt: 'select_account'});
          } else {
            const activeAccount = currentAccounts[0];
            MsalAuthService.authContext.setActiveAccount(activeAccount);
            getTenantsAndContinuation(activeAccount.name, activeAccount.username);
          }
        }
      });
    }

    static logout = () => {
      MsalAuthService.authContext.logout();
    }

    static shiftAndExecuteGetTokenCall = () => {
      const call = MsalAuthService.getTokenCalls.shift();
      if (call) {
        call.call();
      }
    }

    static createGetTokenCall = (scope, resolve, reject, allowParallelGetTokenAfterComplete) => {
      const resolveToken = ({accessToken}) => {
        if (allowParallelGetTokenAfterComplete) {
          MsalAuthService.executeGetTokenSequentially = false;
        }
        MsalAuthService.gettingToken = false;
        resolve(accessToken);
        MsalAuthService.shiftAndExecuteGetTokenCall();
      };

      return () => {
        MsalAuthService.gettingToken = true;
        MsalAuthService.authContext.acquireTokenSilent(scope)
          .then(resolveToken)
          .catch(error => {
            if (error instanceof Msal.InteractionRequiredAuthError) {
              // Popups are likely to be blocked by the browser
              // Notify the user that they should enable them
              alert("Some authentication flows will require pop-ups, please make sure popups are enabled for this site.");
              MsalAuthService.authContext.acquireTokenPopup(scope).then(resolveToken)
                .catch(error => {
                  console.error(error);
                  resolveToken(error);
                });
            } else if(error && error.errorCode && error.errorCode === 'no_tokens_found'){
              MsalAuthService.authContext.logoutRedirect({
                  onRedirectNavigate: (url) => {
                      // Return false if you would like to stop navigation after local logout
                      return false;
                  }
              }).then(() => location.reload());
            } 
            else {
              console.error(error);
              resolveToken(error);
            }
          });
      };
    }

    static getGenericTokenPromiseCallback = (scope, allowParallelGetTokenAfterComplete = false) => {
      if (MsalAuthService.tenantId !== DEFAULT_TENANT_ID && !scope.authority) {
        scope.authority = `${MsalAuthService.getConstantValue("login")}${MsalAuthService.tenantId}`;
      }
      return (resolve, reject) => {
        const getTokenCall = MsalAuthService.createGetTokenCall(scope, resolve, reject, allowParallelGetTokenAfterComplete);
        MsalAuthService.getTokenCalls.push(getTokenCall);
        if (!MsalAuthService.gettingToken || !MsalAuthService.executeGetTokenSequentially) {
          MsalAuthService.shiftAndExecuteGetTokenCall();
        }
      };
    }

    static getAdxToken = () => {
        const adxApiScope = MsalAuthService.getConstantValue('adxApi');
        return new Promise(
            MsalAuthService.getGenericTokenPromiseCallback({
                scopes: [`${adxApiScope}/user_impersonation`]
            })
        );
    };

    static getAdtToken = () => {
      const adtApiScope = MsalAuthService.getConstantValue("adtApi");
      return new Promise(MsalAuthService.getGenericTokenPromiseCallback({scopes: [ `${adtApiScope}/.default` ]}));
    }

    static getManagementToken = (useLoginAuthority = false) => {
        const management = MsalAuthService.getConstantValue('management');
        const scope = {scopes: [`${management}/.default`]};
        if(useLoginAuthority){
            scope['authority'] = `${MsalAuthService.getConstantValue(
                    'login'
                )}organizations`
        }
        return new Promise(
            MsalAuthService.getGenericTokenPromiseCallback(scope)
        );
    };

    static promiseHttpRequest = (token, url, payload, verb = "POST") => new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) {
          return;
        }

        if (xhr.status === 200 || xhr.status === 202) {
          if (xhr.responseText.length === 0) {
            resolve({});
          } else {
            resolve(xhr.responseText);
          }
        } else {
          reject(xhr);
        }
      };
      xhr.open(verb, url);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.send(payload);
    })

    static ssoSilent = (userName, continuation) => {
      const request = {authority: `${MsalAuthService.getConstantValue("login")}${MsalAuthService.tenantId}`, loginHint: userName};
      const setAccountAndContinue = (response) => {
        if(response.account){
          MsalAuthService.authContext.setActiveAccount(response.account);
        }
        continuation();
      }
      const catchLogic = (err) => {
        console.error('SSO failed');
        console.error(err);
        continuation();
      }
      MsalAuthService.authContext.ssoSilent(request)
        .then(setAccountAndContinue)
        .catch((err) => {
          if (err instanceof Msal.InteractionRequiredAuthError) {
            alert("Some authentication flows will require pop-ups, please make sure popups are enabled for this site.");
            MsalAuthService.authContext.loginPopup(request)
              .then(setAccountAndContinue)
              .catch(catchLogic);
          }
          else{
            catchLogic(err);
          }
        })
    }

    static queryParams = (function() {
        return new URLSearchParams(window.location.search);
    })();

    static getUrlParam = key => {
        return MsalAuthService.queryParams.get(key);
    }
}
