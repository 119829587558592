/* eslint-disable */
import React from "react";
import { MessageBar, MessageBarType, } from "office-ui-fabric-react/lib/";

const DowntimeMessageBar = ({ onDismiss, t }) => {
    return (
        <>
            <MessageBar
                aria-label={t("downtimeMessaging.title")}
                messageBarType={MessageBarType.warning}
                onDismiss={onDismiss}
                role="alert"
                styles={{
                    root: {
                        position: "absolute",
                        top: 0,
                        zIndex: 1
                    }
                }}
                isMultiline={false}
            >
                {t("downtimeMessaging.title")}
            </MessageBar>
        </>
    );
};

export default DowntimeMessageBar;
