import { getDebugLogger } from "../../src/utils/utilities";
import { LOCAL_STORAGE_KEYS } from "./Constants";
import { CUSTOM_PROPERTY_NAMES } from "./models/Constants/TelemetryConstants";
const md5 = require("js-md5");

// False to avoid undefined debugLogging case
const debugLogging = localStorage.getItem(LOCAL_STORAGE_KEYS.FeatureFlags.Telemetry.debugLogging) === "true" || false;
const logDebugConsole = getDebugLogger("TelemetryService", debugLogging);

export default class TelemetryService {

    // Callback that will trigger telemetry calls
    static telemetryCallback;

    // Hashed value of the adt instance URL
    static adtInstanceHash;

    // Attach telemetry processing callback from consuming application
    static registerTelemetryCallback(telemetryCallback) {
      TelemetryService.telemetryCallback = telemetryCallback;
    }

    // #region public telemetry emitters

    /**
     * Send Telemetry event
     * @param telemetry Object with following structure:
     *  name: string
     *  triggerType: 'UserAction' | 'SystemAction',
     *  componentName: Component names defined in TelemetryConstants,
     *  appRegion: App region names defined in TelemetryConstants
     *  customProperties: Object with any additional values
     */
    static sendEvent(telemetry) {
      this.sendTelemetry(telemetry);
    }

    /**
     * Send Telemetry exception
     * @param telemetry Object with following structure:
     *  name: string
     *  exceptionId: string (identifier of where the exception was thrown, to group exception from same source),
     *  severityLevel?: Severity levels defined in TelemetryConstants,
     *  message?: string,
     *  stack?: string
     */
    static sendException(telemetry) {
      this.sendTelemetry(telemetry);
    }

    /**
     * Send Telemetry metric
     * @param telemetry Object with following structure:
     *  average: number,
     *  min?: number,
     *  max?: number,
     *  sampleSize?: number
     */
    static sendMetric(telemetry) {
      this.sendTelemetry(telemetry);
    }

    /**
     * Send Telemetry for request
     * @param telemetry Object with following structure:
     *  url: string,
     *  success?: boolean,
     *  responseCode?: number,
     *  responseMessage?: string,
     *  requestMethod?: string
     */
    static sendRequest(telemetry) {
      this.sendTelemetry(telemetry);
    }

    /**
     * Send Telemetry trace
     * @param telemetry Object with following structure:
     *  message: string,
     *  severityLevel?: Severity levels defined in TelemetryConstants
     */
    static sendTrace(telemetry) {
      this.sendTelemetry(telemetry);
    }

    // #endregion

    // #region public setters

    /**
     * Store the current adt instance
     * @param adtInstanceUrl
     */
    static setAdtInstance(adtInstanceUrl) {
      if (typeof adtInstanceUrl === "string") {
        this.adtInstanceHash = md5(adtInstanceUrl);
      } else {
        this.adtInstanceHash = "";
      }
      logDebugConsole(
        "debug",
        `Updating adt instance hash to ${this.adtInstanceHash}. {adtInstance}`,
        adtInstanceUrl
      );
    }

    // #endregion

    // #region private methods

    // Report telemetry to telemetry processing callback (if present)
    static sendTelemetry(telemetry) {
      if (!telemetry) {
        return;
      }
      // Add the common properties for all events
      TelemetryService.addCommonTelemetryProperties(telemetry);
      logDebugConsole(
        "debug",
        `[Telemetry] [${telemetry?.type}] ${telemetry?.name}`,
        telemetry
      );
      if (TelemetryService.telemetryCallback) {
        TelemetryService.telemetryCallback(telemetry);
      }
    }

    static addCommonTelemetryProperties(telemetry) {
      if (!telemetry.customProperties) {
        telemetry.customProperties = {};
      }
      // Add the adt instance hash
      if (this.adtInstanceHash) {
        telemetry.customProperties[CUSTOM_PROPERTY_NAMES.AdtInstanceHash] = this.adtInstanceHash;
      }
    }

  // #endregion

}
